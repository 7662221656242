import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { CloudUploadOutlined, ShareAltOutlined, LikeOutlined, MessageOutlined, StarFilled } from '@ant-design/icons';
import { MyLikes } from "./myLikes";
import { MyMsg } from "./myMsg";
import { MyFilefeedback } from "./myFilefeedback";
import { MyArchive } from './myArchive';
import { MyRepo } from './myRepo';

export const Profile = () => {
    const [menuKey, setMenuKey] = useState('addRepo');

    const menuItem = [
        {label: '上傳紀錄',key: 'addRepo',icon: <CloudUploadOutlined />},
        {label: '收藏及下載紀錄',key: 'archiveRepo',icon: <StarFilled />},
        {label: '分享紀錄',key: 'fileFeedbackRepo',icon: <ShareAltOutlined />},
        {label: '留言紀錄',key: 'msgFeedbackRepo',icon: <MessageOutlined />},
        {label: '按讚紀錄',key: 'likeRepo',icon: <LikeOutlined />}              
    ];
    
    const onMenuClick = (e) => {
        console.log('click ', e);
        setMenuKey(e.key);
    };

    return (
        <div>
            <Menu onClick={onMenuClick} selectedKeys={[menuKey]} mode="horizontal" items={menuItem} />
            <div className='pa_20'>
                {menuKey === 'addRepo' && <MyRepo /> }
                {menuKey === 'archiveRepo' && <MyArchive /> }
                {menuKey === 'fileFeedbackRepo' && <MyFilefeedback /> }
                {menuKey === 'msgFeedbackRepo' && <MyMsg/>}
                {menuKey === 'likeRepo' && <MyLikes/> }
            </div>
        </div>
    );
};
