import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, List, Typography, Avatar, message} from 'antd';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';

export const HistoryModal = ({ repoId, setHistoryId }) => {      
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [data, setData] = useState([]);

    const { Paragraph } = Typography;
    const [reloadVer, setReloadVer] = useState(false);

    useEffect(() => {
        setShowModal(true);
        setLoading(true);
        // 撈取版本歷程記錄  files 回傳各版本
        DuckIO.doGet(AppConfig.REPO_COMMITS(repoId), null, (result) => {
            if (result.resultCode !== 10) {
                alert('resultCode '+result.resultCode);
            } else {
                console.log("REPO_COMMITS", result.response)
                setData(result.response);
                setLoading(false); // modal 裡教材資訊撈取結束
            }
        });

    }, [reloadVer]);

    const handleCancel = () => {
        setShowModal(false);
        setHistoryId(null);
    }

    const hiddenCommit = (i,h,v) => {
        setBtnLoading(true);

        DuckIO.doPost(AppConfig.COMMIT_HIDDEN(i,h,v), null, null, (result) => {
            
            if (result.resultCode == 20) {
                message.warning(result.exMessage,1,setBtnLoading(false));
            }  
            else if (result.resultCode !== 10) {
                alert('resultCode '+ result.resultCode);
            }           
            else {
                setBtnLoading(false);
                setReloadVer(!reloadVer); // 重撈跳窗commit
            }
        }); 
    }

    return(
        <Modal
            open={showModal}
            title={`版本紀錄(已公開版本將在平台上顯示)`}
            onCancel={handleCancel}
            footer={[
                <Button key="back" disabled={btnLoading} onClick={handleCancel}>
                    關閉
                </Button>
            ]}
        >
            {loading && <Spin spinning={loading} />}
            {!loading &&
                <List          
                    bordered
                    dataSource={data}
                    renderItem={(x,i) => (
                        <List.Item>
                                <List.Item.Meta
                                    avatar={x.hidden ? 
                                            <Avatar size={50} src={'/images/hidden.png'} /> 
                                            : <Avatar size={50} src={'/images/show.png'} />}
                                    title={!x.message ? '初始版本' : x.message}
                                    description={
                                    <Paragraph ellipsis={{ rows: 2 }} type="secondary">
                                        {x.date || ''}
                                    </Paragraph>
                                    }
                                />
                                <div style={{textAlign:'center'}}>
                                    <Button 
                                        danger 
                                        loading={btnLoading}
                                        onClick={()=>{hiddenCommit(repoId, !x.hidden, x.id)}}>
                                        {x.hidden ? '公開此版本' : '隱藏此版本'}
                                    </Button>
                                </div>
                            </List.Item>
                    )}
                />
            }
        </Modal>
    )
    
}
