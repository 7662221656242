import axios from 'axios'

import {ServerResultCode} from "../constants/ServerResultCode";
import {ResponseModel} from "../model/ResponseModel";

const FileDownload = require('js-file-download');

const BASE_URL = process.env.REACT_APP_BASE_URL //'http://106.1.80.127:38889';
// const BASE_URL = 'https://courses.im.ncue.edu.tw'
const ENV_API_URL = `${BASE_URL}/${process.env.REACT_APP_API_CONTEXT}`;
// const ENV_API_URL = `${BASE_URL}/universal_pro2/api`;
const ENV_RESOURCE_URL = `${BASE_URL}/storage/universal_resources`;

const qs = require('qs');
const request = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    timeout: 30000,
    // headers: {
    //     'Access-Control-Allow-Origin' : '*',
    //     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // }
});

export default class DuckIO {
     static resource(name)   {
        return name.startsWith("http") ? name : `${ENV_RESOURCE_URL}/${name}`;
    }

    static download(url, name, resp) {
        axios({
            withCredentials: true,
            url: url,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            var filename = response.headers['content-disposition'];
            if(!!filename && filename.startsWith("attachment;filename=")) {
                name = filename.substring("attachment;filename=".length);
            }

            FileDownload(response.data, name);

            if(!!resp) {
                resp()
            }
        });
    }

     static path(api) {
        return `${ENV_API_URL}/${api}`;
    }

    static doGet(api, config, callback) {
        if(!!callback) {
            request.get(`${ENV_API_URL}/${api}`, config)
                .then((payload) => { callback(payload.data); })
                .catch((err) => { DuckIO.errorCallbak(err, callback);
                });
        } else {
            return new Promise(resolve => {
                request.get(`${ENV_API_URL}/${api}`, config)
                    .then((payload) => { resolve(payload.data); })
                    .catch((err) => {  DuckIO.errorCallbak(err, resolve); });
            });
        }
    }

    static errorCallbak(err, callback) {
        let resp = new ResponseModel();

        resp.resultCode = ServerResultCode.NETWORK_ERROR;
        resp.exMessage = err.message;
        callback(resp);
    }

    static doPost(api, data, config, callback) {
        if(!!callback) {
            request.post(`${ENV_API_URL}/${api}`, data, config)
                .then((payload) => {  callback(payload.data); })
                .catch((err) => { DuckIO.errorCallbak(err, callback); });
        } else {
            return new Promise(resolve => {
                request.post(`${ENV_API_URL}/${api}`, data, config)
                    .then((payload) => {  resolve(payload.data); })
                    .catch((err) => { DuckIO.errorCallbak(err, resolve); });
            });
        }
    }

    static doFileUpload(api, file, callback) {
        const formData = new FormData();

        if(!!file && !!file.buffer) {
            formData.append('file', file.buffer, file.name);
        } else {
            formData.append('file', file);
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        return DuckIO.doPost(api, formData, config, callback);
    }

    static doFormdata(api, data, callback) {
        if(!!callback) {
            request.post(`${ENV_API_URL}/${api}`, qs.stringify(data), {
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
            }).then(
                (payload) => {
                    callback(payload.data);
                }).catch((err) => { DuckIO.errorCallbak(err, callback); });
        } else {
            return new Promise(resolve => {
                request.post(`${ENV_API_URL}/${api}`, qs.stringify(data), {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
                }).then(
                    (payload) => {  resolve(payload.data);
                    }).catch((err) => { DuckIO.errorCallbak(err, resolve); });
            });
        }
    }

    static doPut(api, data, callback) {
        if(!!callback) {
            request.put(`${ENV_API_URL}/${api}`, data).then(
                (payload) => {
                    callback(payload.data);
                }).catch((err) => {
                DuckIO.errorCallbak(err, callback);
            });
        } else {
            return new Promise(resolve => {
                request.put(`${ENV_API_URL}/${api}`, data).then(
                    (payload) => {
                        resolve(payload.data);
                    }).catch((err) => {
                    DuckIO.errorCallbak(err, resolve);
                });
            });
        }
    }

    static doDelete(api, callback)  {
        if(!!callback) {
            request.delete(`${ENV_API_URL}/${api}`).then(
                (payload) => {
                    callback(payload.data);
                }).catch((err) => {
                DuckIO.errorCallbak(err, callback);
            });
        } else {
            return new Promise(resolve => {
                request.delete(`${ENV_API_URL}/${api}`).then(
                    (payload) => {
                        resolve(payload.data);
                    }).catch((err) => {
                    DuckIO.errorCallbak(err, resolve);
                });
            });
        }
    }
}
