export class ResponseModel {
    _resultCode;
    _response;
    _exMessage;

    get resultCode() {
        return this._resultCode;
    }

    set resultCode(value) {
        this._resultCode = value;
    }

    get response() {
        return this._response;
    }

    set response(value) {
        this._response = value;
    }

    get exMessage() {
        return this._exMessage;
    }

    set exMessage(value) {
        this._exMessage = value;
    }
}

export class ResponseModelNull {

}
