
import React from 'react';
import AppConfig from "../config/AppConfig";
import DuckIO from "../ducks/DuckIO";
import {
    FileBrowser,
    FileList,
    FileNavbar,
    FileToolbar,
    setChonkyDefaults,
} from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

setChonkyDefaults({ iconComponent: ChonkyIconFA });


const rootFolderId = ""
const storyName = 'File Browser';

class FileListView extends React.Component {

    handleFileAction(action, data) {
        if(action.id = 'open_files') {
            let payload = action.payload
            if(!!payload ) {
                let doubleClick = payload.clickType == 'double'
                console.log(  payload )

                if(payload.file ) {
                    let file = payload.file
                    if(doubleClick) {
                        if(file.isDir) {
                            this.handleOpenDir(payload.file.name)
                        } else {
                            const { id } = this.getParams()
                            DuckIO.download(
                                DuckIO.path(
                                    AppConfig.REPO_DOWNLOAD(id, file.name)),
                                file.name
                            )
                        }
                    }
                }else if(!!payload.targetFile && payload.targetFile.id == 'root') {
                    this.handleOpenDir('', true);
                }
            }
        }
    }

    handleOpenDir(name, toRoot) {
        const { id } = this.getParams()

        DuckIO.doGet(AppConfig.REPO_FILES(id, name), null, (result) => {
            let { folderChain } = this.state;
            if(!folderChain || toRoot) {
                folderChain = []
                folderChain.push({ id: 'root', name: ' '  })
            } else {
                let folderChain2 = [...folderChain];
                folderChain = folderChain2
            }

            folderChain.push({ id: name, name: name  } )

            this.setState({ "files": result.response, "folderChain": folderChain })
        })
    }

    componentDidMount() {
        const { id } = this.getParams()

        DuckIO.doGet(AppConfig.REPO_FILES(id), null, (result) => {
            this.setState({ "files": result.response})
        })

    }

    getParams() {
        let match = this.props['match']; // .params
        if (match && match.params) return match.params;
        return {};
    }

    // useFileActionHandler(folderId) {
    //     return useCallback(
    //         (data) => {
    //             if (data.id === ChonkyActions.OpenFiles.id) {
    //                 const { targetFile, files } = data.payload;
    //                 const fileToOpen = targetFile ?? files[0];
    //                 if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
    //                     setCurrentFolderId(fileToOpen.id);
    //                     return;
    //                 }
    //             }
    //
    //             showActionNotification(data);
    //         },
    //         [setCurrentFolderId]
    //     );
    // }



    render() {
        //const [currentFolderId, setCurrentFolderId] = useState(rootFolderId);
        let files = !!this.state && !!this.state.files ? this.state.files : [] ;
        let folderChain =  !!this.state && !!this.state.folderChain ? this.state.folderChain : [] ;
        let fileList = []
        // [{ id: 1 ,name: "1", isDir: true }]

        files.forEach((data) => {
            fileList.push({ id: data.name, name: data.name, isDir: data.isFolder,

                draggable: false })
        });


        return (
            <div>
                <FileBrowser
                    instanceId={storyName}
                    files={ fileList }
                    folderChain={ folderChain }
                    disableSelection={true}
                    onFileAction={this.handleFileAction.bind(this)}>
                    <FileNavbar />
                    <FileToolbar />
                    <FileList />
                </FileBrowser>

            </div>
        );
    }
}

export const File = FileListView
