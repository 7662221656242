
class AppConfig {
    static MEMBER;
    static DEFAULT_MAX_PAGE_SIZE = 10;

    static SIGNIN_MEMBER() { return "mb/signin"; }
    static SIGNED_MEMBER() { return "mb/signed"; }
    static SIGNOUT_MEMBER() { return "mb/signout"; }
    static REPO_INFO() { return `/repo/info`}
    
    static REPO_LIKE(id = '', like = false) { return `/repo/join/like?id=${id}&like=${like}` }
    static REPO_SHARED_INC(id = '') {return `/repo/web/sharedInc?id=${id}` }
    static REPO_SHARED_LINK(id = '') { return `/repo/web/sharedLink?id=${id}`; }
    static REPO_FILES(id = '', path = '') { return `/repo/${id}/files?path=${path}` }
    //static REPO_DOWNLOAD(id= '', path) { return `/repo/${id}/download?path=${path}` }
    static REPO_FILE_UPLOAD(id = '') { return `repo/upload/${id}`}

    static REPO_TRACK(id = '', track = false) { return `/repo/join/track?id=${id}&track=${track}` }
    static MESSAGING_UNREAD() { return `/msg/unread`}
    static MESSAGING_GROUP() { return `/msg/group`}
    static MESSAGING_SUB_LIST(from) { return `/msg/list?from=${from}&orderBy=updateAt:desc`}

    // 教材列表、搜尋
    static REPO_ALL(query = '', page = 1, maxRecord = AppConfig.DEFAULT_MAX_PAGE_SIZE) {
        if(!!query) query += `&page=${page}&maxRecord=${maxRecord}`
        else query += `page=${page}&maxRecord=${maxRecord}`

        return `/repo/all?${query}`;
    }

    static REPO_FAVORITE(query = '', page = 1, maxRecord = AppConfig.DEFAULT_MAX_PAGE_SIZE) { 
        if(!!query) query += `&page=${page}&maxRecord=${maxRecord}`
        else query += `page=${page}&maxRecord=${maxRecord}`

        return `/repo/favorite?${query}`;
    }
    
    static REPO_MY_EXPERTISE(keyword = '', page = 1, maxRecord = AppConfig.DEFAULT_MAX_PAGE_SIZE) { return `/repo/myExpertise?keyword=${keyword}&page=${page}&maxRecord=${maxRecord}`; }

    // 領域列表全部資料
    static CLS_ALL(levelType = '') { return `cls/all?levelType=${levelType}` }

    // 年級列表
    static GRADE_LIST() { return `/grade/list` } 

    // 特教班別
    static CLASS_LIST() { return `/cls/clsTypes` }

    // 障礙類別
    static SP_LIST() { return `/cls/sp_list` }

    // 上傳教材
    static REPO_WEB_UPLOAD(uid = '') { return `repo/web/upload?uid=${uid}`}

    //下載教材
    static REPO_DOWNLOAD_ARCHIVE(id = '', commitSha = "") { return `/repo/archive?id=${id}&commitSha=${commitSha}`}

    // 我的專區 > 我上傳的教材
    static REPO_MY_REPO(keyword = '', page, maxRecord) { return `/repo/myRepo?keyword=${keyword}&page=${page}&maxRecord=${maxRecord}`; }

    // 我的專區 > 對其他教材分享紀錄
    static REPO_MY_FORK() { return `repo/myForkList` }

    // 我的專區 > 我按讚的教材
    static REPO_LIKE_ALL(page, maxRecord) { return `repo/like/all?page=${page}&maxRecord=${maxRecord}` }

    // 我的專區 > 我收藏的教材
    static USER_ARCHIVE (page, maxRecord) { return `repo/archive/all?page=${page}&maxRecord=${maxRecord}`}

    // 我的專區 > 新建教材
    static REPO_ADD(name = '', clsId, classType, grade, description) { return `repo/web/add`}

    // 我的專區 > 標示為不適用
    static REPO_INVALID(repoId = '', invalid) { return `/repo/invalid/${repoId}?invalid=${invalid}`}

    // 我的專區 > 上傳新版本
    static REPO_UPDATE(id= '') { return `repo/update2/${id}`}

    // 我的專區 > 修改當前版本  &  詳細頁 > 更新紀錄
    static REPO_COMMITS(id = '') { return `repo/${id}/commits`}

    // 我的專區 > 留言回饋紀錄
    static MY_MSG_FEEDBACK(page, maxRecord) { return `/repo/myMsgFeedback?page=${page}&maxRecord=${maxRecord}`}

    // 我的專區 > 教材回饋紀錄
    static MY_FILE_FEEDBACK(page, maxRecord) { return `/repo/myFileFeedback?page=${page}&maxRecord=${maxRecord}`}
    
    // 刪除該commit 改做COMMIT_HIDDEN功能
    //static REPO_REVERT(id = '') { return `repo/revert?repoId=${id}` }

    // 隱藏or公開commit
    static COMMIT_HIDDEN(id = '', hidden, commitId) { return `repo/hidden/${id}?hidden=${hidden}&commitId=${commitId}` }

    // 我的專區 > 下載紀錄
    static REPO_MY_DownloadRepo(keyword = '', page, maxRecord) { return `/repo/myDownloadRepo?keyword=${keyword}&page=${page}&maxRecord=${maxRecord}` }

    // 詳細頁 > 教材詳細內容 
    static REPO_DETAIL(id = '') { return `/repo/web/detail?id=${id}`}

    // 詳細頁 > 收藏教材
    static REPO_ARCHIVE(id = '', archive) { return `/repo/join/archive?id=${id}&archive=${archive}`}

    // 詳細頁 > 撈取教材回饋
    static REPO_FILE_FEEDBACK(id = '') { return `repo/fileFeedback?id=${id}`}

    // 詳細頁 > 撈取留言回饋
    static REPO_MSG_FEEDBACK(id = '') { return `repo/msgFeedback?id=${id}`}

    // 詳細頁 > 對此版本回饋
    static POST_FILE_FEEDBACK() { return `repo/post/fileFeedback` }

    // 詳細頁 -> 留言回饋&教材回饋的再回覆
    static POST_REPLY_FEEDBACK() { return `reply/replyFeedback`}

    // 下載特定檔案
    static REPO_DOWNLOAD(id, path='', ref='') { return `repo/${id}/download?path=${path}&ref=${ref}` }

    // 回覆列表
    static REPO_REPLY_LIST(id) {
        return `reply/${id}`
    }

    // 個人設定
    static MY_CONFIG(mbSn = '') { return `/mb/${mbSn}/detail`}
    //
    static MY_UPDATE(mbSn = '') { return `/mb/${mbSn}/update`}

}

export default AppConfig;
