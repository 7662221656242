import React, { useEffect, useState } from 'react';
import { List, Button, Row, Col, Tabs, Typography, message } from 'antd';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';
import { StarFilled, DownloadOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

export const MyArchive = () => {
    const [archiveLoading, setArchiveloading] = useState(true);
    const [downloadLoading, setDownloadloading] = useState(true);
    const [downloadData, setDownloadData] = useState([]);
    const [archiveData, setArchiveData] = useState([]);
    const [btnId, setBtnId] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [ARpageMaxSize, setARpageSize] = useState(AppConfig.DEFAULT_MAX_PAGE_SIZE);
    const [DLpageMaxSize, setDLpageSize] = useState(AppConfig.DEFAULT_MAX_PAGE_SIZE);

    useEffect(() => {   
        DuckIO.doGet(AppConfig.USER_ARCHIVE(1, ARpageMaxSize), null, (result) => {
            setArchiveData(!!result.response ? result.response : []);
            setArchiveloading(false);
        });

        DuckIO.doGet(AppConfig.REPO_MY_DownloadRepo('', 1, DLpageMaxSize), null, (result) => {
            setDownloadData(!!result.response ? result.response : []);
            setDownloadloading(false);
        });
    }, []);

    return (
        <Row justify="center">
        <Col xs={24}>
            <Tabs
                defaultActiveKey="1"
                items={[StarFilled, DownloadOutlined ].map((Icon, i) => {
                const id = String(i + 1);
                    return {
                        label: (
                        <span>
                            <Icon key={i} />
                            {id === '1' ? '收藏紀錄' : '下載紀錄'}
                        </span>
                        ),
                        key: id,
                        children:  id === '1' ? 
                        <List
                            className='content_box'
                            key={i}
                            locale={{ emptyText:'無收藏教材'}}
                            loading={archiveLoading}
                            dataSource={archiveData.items} // 留言紀錄
                            pagination={{
                                onChange: (page, pageSize) => {
                                        setARpageSize(pageSize);
                                        DuckIO.doGet(AppConfig.USER_ARCHIVE(page,pageSize), null, (result) => {
                                            setArchiveData(!!result.response ? result.response : []);
                                        });
                                },
                                showSizeChanger: true,
                                current: archiveData.page,
                                pageSize: ARpageMaxSize,
                                total: archiveData.totalRecords,
                                itemRender: (current, type, originalElement) => {
                                    if (type === 'prev') {
                                        return <a>＜</a>;
                                    }
                                    if (type === 'next') {
                                        return <a>＞</a>;
                                    }
                                    return originalElement;
                                },
                            }}
                            renderItem={x => (
                                <List.Item key={x.id}>
                                    <List.Item.Meta
                                        title={x.name}
                                        description={x.username}
                                    />
                                <>
                                <Button style={{margin:'1px 5px'}} type="primary" 
                                    loading={btnId===x.id} 
                                    disabled={btnId !== x.id && btnLoading} 
                                    onClick={() => {
                                        setBtnId(x.id);
                                        setBtnLoading(true)
                                        DuckIO.download(DuckIO.path(AppConfig.REPO_DOWNLOAD_ARCHIVE(x.id)), `${x.id}.zip`, () => {
                                            message.success('下載成功');
                                            setBtnId('');
                                            setBtnLoading(false)
                                        });
                                    }}> {btnId===x.id ? '下載中' :'下載檔案'}
                                </Button>
                                <Button style={{margin:'1px 5px'}} type="default" onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(`/repo/${x.id}`);
                                    }}>查看此教材
                                </Button>  
                                </>                                      
                            </List.Item>           
                            )}
                        /> : 
                        <List
                            className='content_box'
                            locale={{ emptyText:'無下載紀錄'}}
                            loading={downloadLoading}
                            dataSource={downloadData.items}
                            pagination={{
                                onChange: (page,pageSize) => {
                                        setDLpageSize(pageSize);
                                        //get下載紀錄API List
                                        DuckIO.doGet(AppConfig.REPO_MY_DownloadRepo('', page, pageSize), null, (result) => {
                                            setDownloadData(!!result.response ? result.response : []);
                                        });
                                },
                                showSizeChanger: true,
                                current: downloadData.page,
                                pageSize: DLpageMaxSize,
                                total: downloadData.totalRecords,
                                itemRender: (current, type, originalElement) => {
                                    if (type === 'prev') {
                                        return <a>＜</a>;
                                    }
                                    if (type === 'next') {
                                        return <a>＞</a>;
                                    }
                                    return originalElement;
                                },
                            }}
                            renderItem={x => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={x.title}
                                        description={
                                        <Paragraph ellipsis={{ rows: 2 }} type="secondary">
                                            {x.intro || ''}
                                        </Paragraph>
                                        }
                                    />
                                    <div style={{textAlign:'center'}}>
                                        <p>教材擁有者：{x.teacher}</p>
                                        <Button type="primary" onClick={(event) => {
                                                event.stopPropagation();
                                                window.open(`/repo/${x.id}`);
                                            }}>查看</Button>
                                    </div>
                                </List.Item>
                            )}
                        />
                }})}
            />
        </Col>
    </Row>
    );
}
