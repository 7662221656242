import React, { useEffect, useState } from 'react';
import { List, Button, Row, Col, Typography } from 'antd';
import DuckIO from '../ducks/DuckIO';
import { PlusCircleOutlined, CloudUploadOutlined } from '@ant-design/icons';
import AppConfig from '../config/AppConfig';
import { UpdateModal } from './updateModal';
import { HistoryModal } from './historyModal';
import { AddRepoModal } from "./addrepoModal";
import { UpdateRepoModal } from "./updateRepoModal";

const { Paragraph } = Typography;

export const MyRepo = () => {
    const [myRepodataLoading, setMyRepoDataLoading] = useState(true);
    const [myRepodata, setMyRepoData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [historyId, setHistoryId] = useState(null);
    const [editRepoId, setEditRepoId] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [pageMaxSize, setPageSize] = useState(AppConfig.DEFAULT_MAX_PAGE_SIZE);

    useEffect(() => {
        DuckIO.doGet(AppConfig.REPO_MY_REPO('', 1,pageMaxSize), null, (result) => {
            setMyRepoData(!!result.response ? result.response : []);
            setMyRepoDataLoading(false)
        }); 
        
    }, [myRepodataLoading]);

    const passParam = (id, action) => {
        if (action === 'editFile'){
            setEditId(id);

        } else if (action === 'history'){
            setHistoryId(id);

        } else if (action === 'editRepo'){
            setEditRepoId(id);

        } else {
            return null;
        }
    }
    return (
        <>
        <Row justify="center">
            <Col xs={24}>
                <div className='title_pb20'><CloudUploadOutlined />我上傳的教材
                    <Button style={{backgroundColor:'#FFA20E',color:'white', marginLeft: '5px', borderColor:'#FFA20E'}} 
                            shape="round" 
                            icon={<PlusCircleOutlined />}
                            onClick={()=>{setShowAddModal(true);}}
                    >
                    上傳教材
                    </Button>
                </div>
                <List
                locale={{emptyText: '無上傳教材紀錄'}}
                className='content_box'
                loading={myRepodataLoading}
                dataSource={myRepodata.items}
                pagination={{
                    onChange: (page, pageSize) => {
                            setPageSize(pageSize);
                            //get我上傳的教材API List
                            DuckIO.doGet(AppConfig.REPO_MY_REPO('', page, pageSize), null, (result) => {
                                setMyRepoData(!!result.response ? result.response : []);
                            });
                    },
                    showSizeChanger: true,
                    current: myRepodata.page,
                    pageSize: pageMaxSize,
                    total: myRepodata.totalRecords,
                    itemRender: (current, type, originalElement) => {
                        if (type === 'prev') {
                            return <a>＜</a>;
                        }
                        if (type === 'next') {
                            return <a>＞</a>;
                        }
                        return originalElement;
                    },
                }}
                renderItem={x => (
                    <List.Item>
                        <List.Item.Meta
                            style={{minWidth:'200px'}}
                            title={x.title}
                            description={
                            <Paragraph ellipsis={{ rows: 2 }} type="secondary">
                                {x.intro || ''}
                            </Paragraph>
                            }
                        />
                        <div>
                            <Button type="default" onClick={(event) => {
                                event.stopPropagation();
                                window.open(`/repo/${x.id}`);
                            }}>查看此教材
                            </Button>
                            <Button style={{border:'none',color: 'white', margin:'1px 5px', backgroundColor: '#55a993'}} onClick={() => {
                                passParam(x.id,'editRepo');

                            }}>編輯教材資訊</Button> 
                            <Button type="primary" onClick={() => {
                                passParam(x.id,'editFile');

                            }}>上傳新版本</Button>
                            <Button type="primary" onClick={() => {
                                passParam(x.id,'history');
                            }}>版本紀錄</Button>
                            <Button style={{border:'none',color: 'white', margin:'1px 5px', backgroundColor: !x.invalid ? 'red' : '#30da30'}} 
                            onClick={(e)=>{
                                e.stopPropagation();
                                DuckIO.doPost(AppConfig.REPO_INVALID(x.id, !x.invalid), null, null, (result) => {
                                    console.log(result)
                                });
                                setMyRepoDataLoading(!myRepodataLoading); 
                            }}>
                                {x.invalid ? '標示教材為適用' : '標示教材已不適用'}
                        </Button>
                        </div>
                    </List.Item>
                )}
                />
            </Col>
        </Row>
        {editId && 
            <UpdateModal editId={editId} setEditId={setEditId} setMyRepoDataLoading={setMyRepoDataLoading} myRepodataLoading={myRepodataLoading} />
        }
        {editRepoId && 
            <UpdateRepoModal editRepoId={editRepoId} setEditId={setEditRepoId} setMyRepoDataLoading={setMyRepoDataLoading} myRepodataLoading={myRepodataLoading} />
        }
        {historyId && 
            <HistoryModal repoId={historyId} setHistoryId={setHistoryId} />
        }
        {showAddModal && 
            <AddRepoModal showAddModal={showAddModal} setShowAddModal={setShowAddModal} setMyRepoDataLoading={setMyRepoDataLoading} myRepodataLoading={myRepodataLoading} />
        }
    </>
    );
}
