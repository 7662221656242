import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, Form, Input, Upload, Select, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';

export const UpdateModal = ({editId, setEditId, setMyRepoDataLoading, myRepodataLoading}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true); // modal 裡教材資訊撈取 loading
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);  // modal 儲存按鈕loading完 再將modal關閉
    const [data, setData] = useState({});
    const [showEdit, setShowEdit] = useState(false); // modal 視窗

    useEffect(() => {
        if (!editId) {
            alert('請洽管理員');

        } else {
            setShowEdit(true);
            DuckIO.doGet(AppConfig.REPO_DETAIL(editId), null, (result) => {
              if (result.resultCode !== 10) {
                  alert('resultCode '+result.resultCode);
              } else {
                setData(result.response);
                setLoading(false);
              }
          });
        }
    }, []);

    const handleCancel = () => {
        setShowEdit(false);
        setEditId(null);
    }

    const handleOk = () => {
        setSubmitBtnLoading(true);
        form.submit();  //會呼叫form 函式 onFinish實作表單
    };

    const onFinish = (values) => {
        let filedata=[];

        values.upload.forEach(y=>{
          if(y.status) { // 有status => 新上傳檔案 將name、uid紀錄
            filedata.push({ name: y.name, uid: y.uid });
          }
          else { // 不更動原本的 只紀錄name
            filedata.push({ name: y.name });
          }
        });

        let v = {...values, upload: filedata};

        DuckIO.doPost(AppConfig.REPO_UPDATE(editId), v, null, (result) => {
          if (result.resultCode !== 10) {
            message.error(result.exMessage);
            setSubmitBtnLoading(false);
          }
          else {
            setSubmitBtnLoading(false);
            setMyRepoDataLoading(!myRepodataLoading); //回上層重撈進一次撈list
            setEditId(null);
            setShowEdit(false);
          }
        });
        

      };
    
    const onFinishFailed = (errorInfo) => {
        setSubmitBtnLoading(false);
    };

    const normFile = (e) => {
        setSubmitBtnLoading(true);
        
        if (Array.isArray(e)) {
          return e;
        }

        if (e.file.status !== 'uploading') {
          setSubmitBtnLoading(false)
        }
        return e?.fileList;
      };
    
    
      return (
        <>
          <Modal
            open={showEdit}
            title={`上傳新版本`}
            maskClosable={false}
            closable={!submitBtnLoading}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                取消
              </Button>,
              <Button key="submit" type="primary" loading={submitBtnLoading} onClick={handleOk} disabled={loading}>
                {submitBtnLoading ? '儲存中' : '儲存'}
              </Button>
            ]}
          >
            { (loading || data==={})  && <Spin spinning={loading} />}
            {(!loading)  &&
            <Form
                form={form}
                disabled={submitBtnLoading}
                onFinish={onFinish}  // 驗證通過呼叫
                onFinishFailed={onFinishFailed} // 驗證不通過呼叫
                initialValues={{ upload:data.files }}
                //autoComplete="off"
            >
                <Form.Item key="message" label="更新內容與敘述" name="message" rules={[{ required: true, message: '請說明此次更新的內容' }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    key="upload"
                    name="upload"
                    label="上傳教材"
                    method={"post"}  //預設post
                    valuePropName="fileList"
                    getValueFromEvent={normFile} // 每選擇一次教材會觸發一次
                    multiple={true} //可多選文件
                    onChange={(a)=>{console.log(a)}}
                >
                    <Upload
                       name="file"
                       // action={DuckIO.path(AppConfig.REPO_WEB_UPLOAD())} //放進redis
                       listType="text" //上傳文件類型
                       customRequest={(option) => {
                           const file = option.file
                           DuckIO.doFileUpload(AppConfig.REPO_WEB_UPLOAD(file.uid), file, (result) => {
                               if(result.resultCode == 10) {
                                   option.onSuccess(file);
                               } else {
                                   message.error(result.exMessage);
                                   option.onError(result.exMessage);

                                   let nowList = form.getFieldValue("upload");
                                   nowList = nowList.filter(item => item.uid !== file.uid)
                                   form.setFieldValue("upload", nowList);

                               }
                           })
                       }}
                       data={file => ({
                           uid: file.uid
                       })}
                       beforeUpload={(file)=>{
                        let nowList = form.getFieldValue("upload");
                        let nowFileName = file.name;
                       
                        let hasDuplicateName = nowList.some(x=>{
                          return x.name === nowFileName;
                        });

                        if (hasDuplicateName) {
                          message.error("不可上傳相同檔名，請更新檔名或刪除舊檔案");
                          return Upload.LIST_IGNORE;
                        }

                        return true;
                        
                       }}
                      defaultFileList={data.files}
                    >
                        <Button icon={<UploadOutlined />}>選擇檔案</Button>
                        <span className='memo'>(支援上傳多個檔案，檔案名稱不可重複)</span>
                    </Upload>
                </Form.Item>
            </Form>

            }
          </Modal>
        </>
      )
}
