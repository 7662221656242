import React, { useEffect, useState } from 'react';
import { Button, Modal, Input, message, Form, Select, Upload, Spin, Tag } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';
import { AreaModal_Edit } from '../components';

const recursiveClsIds = (response, array) => {
    for(var i=0; i<response.length; ++i) {
        var subs = response[i].subs;
        if(!!subs) {
            recursiveClsIds(subs, array);
        } else {
            array.push(response[i].classId)
        }
    }
}

export const AddRepoModal = ({ showAddModal, setShowAddModal, setMyRepoDataLoading, myRepodataLoading }) => {  
    const [form] = Form.useForm();
    const [gradeList, setGradeList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [spList, setSpList] = useState([]);

    const [areaTEvalue, setAreaTEvalue] = useState([]); 
    const [areaCLvalue, setAreaCLvalue] = useState([]); 

    const [modalOpen, setModalOpen] = useState(false); 
    
    const [gradeLoading, setGradeLoading] = useState(true);
    const [classLoading, setClassLoading] = useState(true);
    const [spLoading, setSpLoading] = useState(true);
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);  // modal 儲存按鈕loading完 再將modal關閉

    useEffect(() => {
        
        DuckIO.doGet(AppConfig.GRADE_LIST('', 1), null, (result) => {
            if (result.resultCode !== 10) {
                alert('resultCode '+result.resultCode);
            } else {
                let glist = result.response.map((x)=>{
                    return {label: x.name, value: x.id}
                });
                setGradeList(!!result.response ? glist : []);
                setGradeLoading(false)
            }
        });
        
        DuckIO.doGet(AppConfig.CLASS_LIST('', 1), null, (result) => {
            if (result.resultCode !== 10) {
                alert('resultCode '+result.resultCode);
            } else {
                let clist = result.response.map((x)=>{
                    return {label: x.name, value: x.key}
                });
                setClassList(!!result.response ? clist : []);
                setClassLoading(false)
            }
        });

        DuckIO.doGet(AppConfig.SP_LIST('', 1), null, (result) => {
            if (result.resultCode !== 10) {
                alert('SP_LIST resultCode '+result.resultCode);
            } else {
                let splist = result.response.map((x)=>{
                    return {label: x.name, value: x.id}
                });
                setSpList(!!result.response ? splist : []);
                setSpLoading(false)
            }
        });

        setShowAddModal(true);
    }, []);

    const handleCancel = () => {
        form.resetFields();
        setShowAddModal(false);
    }

    const handleOk = () => {
        setSubmitBtnLoading(true);
        form.submit();  //會呼叫form 函式 onFinish實作表單
    };

    const onFinish = (values) => {
        let data = [];
        let spTemp = values.spobs;
        let clTemp = values.classId;

        (values.upload || []).forEach(y=>{
            data.push({ name: y.name, uid: y.uid });
        });

        spTemp = !values.spobs ? [] : values.spobs;
        clTemp = clTemp.map(x=>x.value);

        let v = {...values, upload: data, spobs: spTemp, classId: clTemp};
        
        DuckIO.doPost(AppConfig.REPO_ADD(), v, null, (result) => {
            if (result.resultCode !== 10) {
                message.error(result.exMessage);
                setSubmitBtnLoading(false);
            }
            else {
                setSubmitBtnLoading(false);
                setShowAddModal(false);
                setMyRepoDataLoading(!myRepodataLoading); //回上層重撈進一次撈list
            }
        });
        
      };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setSubmitBtnLoading(false);
    };

    const normFile = (e) => {
        setSubmitBtnLoading(true);

        if (Array.isArray(e)) {
            console.log("Array.isArray")
          return e;
        }
        
        if (e.file.status !== 'uploading') {
            setSubmitBtnLoading(false)
        }

        return e?.fileList;
    };
    

    return(
        <>
        <Modal
            open={showAddModal}
            maskClosable={false}
            closable={!submitBtnLoading}
            //form={form}
            title={`上傳教材`}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel} disabled={submitBtnLoading}>
                取消
              </Button>,
              <Button key="submit" type="primary" loading={submitBtnLoading} onClick={handleOk} disabled={submitBtnLoading}>
                上傳
              </Button>
            ]}
        >
            { ( gradeLoading || classLoading || spLoading)  && <Spin spinning={true} />}
            { !( gradeLoading || classLoading || spLoading) &&
            <Form
                form={form}
                disabled={submitBtnLoading}
                onFinish={onFinish}  // 驗證通過呼叫
                onFinishFailed={onFinishFailed} // 驗證不通過呼叫
            >
                <Form.Item 
                    label="教材名稱" 
                    name="title"
                    rules={[{ required: true, message: '請輸入教材名稱' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="教材簡介" name="intro">
                    <Input />
                </Form.Item>
                <Form.Item 
                    label="領域"
                    name="classId"
                    rules={[{ required: true, message: '請選擇教材領域' }]}
                >   <>
                        <Button onClick={()=> {setModalOpen(true);}}>選擇領域</Button>
                        <div style={{margin:'3px 0px'}}>
                            {areaTEvalue.concat(areaCLvalue).map((x,i)=> {
                                return <Tag color="magenta" key={i}>{x.label}</Tag>
                            })}   
                        </div>
                    </>
                </Form.Item>
                <Form.Item 
                    label="特教班別"
                    name="classType"
                    rules={[{ required: false, message: '請選擇特教班別' }]}
                >
                    <Select
                        placeholder="請選擇特教班別"
                        allowClear
                        options={classList}
                    />
                </Form.Item>
                <Form.Item 
                    label="障礙類別"
                    name="spobs"
                    rules={[{ required: false, message: '請選擇障礙類別' }]}
                >
                    <Select
                        mode="multiple"
                        placeholder="請選擇障礙類別"
                        allowClear
                        options={spList}
                    />
                </Form.Item>
                <Form.Item 
                    label="教材年級" 
                    name="grade"
                    rules={[{ required: false, message: '請選擇教材年級' }]}
                >
                    <Select
                        placeholder="請選擇教材年級"
                        allowClear
                        options={gradeList}
                    />
                </Form.Item>
                <Form.Item
                        name="upload"
                        label="上傳教材"
                        method={"post"}
                        valuePropName="fileList"
                        getValueFromEvent={normFile} // 每選擇一次教材會觸發一次
                        multiple={true} //可多選文件
                        rules={[{ required: true, message: '請上傳檔案' }]}
                    >
                        <Upload
                            name="file"
                            // action={DuckIO.path(AppConfig.REPO_WEB_UPLOAD())} //放進redis
                            listType="text" //上傳文件類型
                            customRequest={(option) => {
                                const file = option.file
                                DuckIO.doFileUpload(AppConfig.REPO_WEB_UPLOAD(file.uid), file, (result) => {
                                    if(result.resultCode == 10) {
                                        option.onSuccess(file);
                                    } else {
                                        message.error(result.exMessage);
                                        option.onError(result.exMessage);

                                        let nowList = form.getFieldValue("upload");
                                        nowList = nowList.filter(item => item.uid !== file.uid)
                                        form.setFieldValue("upload", nowList);

                                    }
                                })
                            }}
                            data={file => ({
                                uid: file.uid
                            })}
                            beforeUpload={(file)=>{
                                let nowList = form.getFieldValue("upload") || [];
                                let nowFileName = file.name;

                                let hasDuplicateName = nowList.some(x=>{
                                  return x.name === nowFileName;
                                });
        
                                if (hasDuplicateName) {
                                  message.error("不可上傳相同檔名，請更新檔名");
                                  return Upload.LIST_IGNORE;
                                }
        
                                return true;
                                
                               }}
                        >
                            <Button icon={<UploadOutlined />}>選擇檔案</Button>
                            <span className='memo'>(支援上傳多個檔案，檔案名稱不可重複)</span>
                        </Upload>    
                </Form.Item>
            </Form>
           }
           
        </Modal>
        <AreaModal_Edit 
            modalOpen={modalOpen} 
            modalControl={setModalOpen} 
            setAreaTEvalue={setAreaTEvalue}
            setAreaCLvalue={setAreaCLvalue}
            areaTEvalue={areaTEvalue}
            areaCLvalue={areaCLvalue}
            setFieldValue={form.setFieldValue}
        />
        </>
    )
}
