import React, { useEffect, useState } from 'react';
import { List, Button, Row, Col, Typography } from 'antd';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';
import {LikeOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

export const MyLikes = () => {
    const [likeLoading, setLikeLoading] = useState(true);
    const [likeData, setLikeData] = useState([]);
    const [pageMaxSize, setPageSize] = useState(AppConfig.DEFAULT_MAX_PAGE_SIZE);

    useEffect(() => {
        console.log("----------MyLikes---------------")
        DuckIO.doGet(AppConfig.REPO_LIKE_ALL(1,pageMaxSize), null, (result) => {
            setLikeData(!!result.response ? result.response : []);
            setLikeLoading(false);
        });
        
    }, [likeLoading]);


    return (
        <Row justify="center">
            <Col xs={24}>
                <div className='title_pb20'><LikeOutlined />按讚紀錄</div>
                <List
                className='content_box'
                loading={likeLoading}
                dataSource={likeData.items}
                locale={{ emptyText:'無按讚紀錄'}}
                pagination={{
                    onChange: (page,pageSize) => {
                        setPageSize(pageSize);
                            //get我按讚的教材API List
                        DuckIO.doGet(AppConfig.REPO_LIKE_ALL(page,pageSize), null, (result) => {
                            setLikeData(!!result.response ? result.response : []);
                        });
                    },
                    showSizeChanger: true,
                    current: likeData.page,
                    pageSize: pageMaxSize,
                    total: likeData.totalRecords,
                    itemRender: (current, type, originalElement) => {
                        if (type === 'prev') {
                            return <a>＜</a>;
                        }
                        if (type === 'next') {
                            return <a>＞</a>;
                        }
                        return originalElement;
                    },
                }}
                renderItem={x => (
                    <List.Item>
                        <List.Item.Meta
                            title={x.name}
                            description={
                            <Paragraph ellipsis={{ rows: 2 }} type="secondary">
                                {x.intro || ''}
                            </Paragraph>
                            }
                        />
                        <div style={{textAlign:'center'}}>
                            <p>教材擁有者：{x.username}</p>
                            <Button type="default" onClick={(event) => {
                                    event.stopPropagation();
                                    window.open(`/repo/${x.id}`);
                                }}>查看此教材</Button>
                        </div>
                    </List.Item>
                )}
                />
            </Col>
        </Row>
    );
};
