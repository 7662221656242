export class ServerResultCode {
    // 執行成功
    static get SUCCESS() { return 10; };
    // 等待驗證
    static get WAIT_VARIFY() { return 101; };

    // 執行失敗
    static get ERROR() { return 20; };
    // 參數錯誤
    static get PARAMETER_ERROR() { return 201; };
    // 帳號錯誤
    static get ID_ERROR() { return 202; };
    // 密碼錯誤
    static get PASSWORD_ERROR() { return 203; };
    // 登入狀態錯誤(未登入)
    static get LOGIN_STATUS_ERROR() { return 204; };
    // 權限錯誤
    static get AUTHORITY_ERROR() { return 205; };
    // 記錄不存在錯誤
    static get RECORD_NOT_EXIST_ERROR() { return 206; };
    // 髒資料
    static get RECORD_DIRTY_ERROR() { return 207; };
    // 記錄重複錯誤
    static get RECORD_DUPLICATE_ERROR() { return 208; };
    // 違法存取錯誤
    static get ILLEGAL_ACCESS_ERROR() { return 209; };
    // 檔案格式錯誤
    static get FILE_FORMAT_ERROR() { return 210; };
    // 檔案大小錯誤
    static get FILE_SIZE_ERROR() { return 211; };
    // 圖檔比例錯誤
    static get IMAGE_SCALE_ERROR() { return 212; };
    // 關連資料存在錯誤
    static get RELEATED_RECORD_EXIST_ERROR() { return 213; };

    // 記錄狀態錯誤
    static get RECORD_STATUS_ERROR() { return 300; };
    // 存在待審核記錄錯誤
    static get PENDING_RECORD_EXIST_ERROR() { return 301; };
    // 不存在待審核記錄錯誤
    static get PENDING_RECORD_NOT_EXIST_ERROR() { return 302; };
    // 不存在合法記錄錯誤
    static get VALID_RECORD_NOT_EXIST_ERROR() { return 303; };

    // 沒有資源錯誤
    static get NO_RESOURCE_ERROR() { return 400; };

    // Client端網路錯誤
    static get NETWORK_ERROR() { return 999; }
}
