import React, { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Button, Typography, List, Row, Col, Space, Input, Tabs, message, Tag } from 'antd';
import { DownloadOutlined, FileTextOutlined , MessageOutlined, FileAddOutlined, StarFilled, LikeFilled  } from '@ant-design/icons';
import { NotFound, Loading, R_Comment } from '../components';
import AppConfig from '../config/AppConfig';
import DuckIO from '../ducks/DuckIO';
import DocumentMeta from 'react-document-meta';
import { FeedbackModal } from "./feedbackModal";

const { Title } = Typography;

export const Detail = () => {
    const [loading, setLoading] = useState(true);
    const [hisLoading, setHisLoading] = useState(true);
    const [msgFbLoading, setMsgFbLoading] = useState(true);
    const [fileFbLoading, setFileFbLoading] = useState(true);
    const [repoFound, setRepoFound] = useState(true);

    const [btnId, setBtnId] = useState('');
    const [downloadLoading, setDownloadLoading] = useState(false);

    const [data, setData] = useState([]);
    const [hisData, setHisData] = useState([]);
    const [msgFbData, setMsgFbData] = useState([]);
    const [fileFbData, setFileFbData] = useState([]);
    const [feedBackModalType, setFeedBackModalType] = useState(null); 
    const [activeReply, setActiveReply] = useState(null);
    const [replySubmitLoading, setReplySubmitLoading] = useState(false);

    const [msgFeedback, setMsgFeedback] = useState('');

    const { id } = useParams();

    useEffect(() => {
        DuckIO.doGet(AppConfig.REPO_DETAIL(id), null, (result) => {
            if (Object.keys(result.response).length === 0) {
                setRepoFound(false);
            } else {
                setData(result.response);
                setLoading(false);
            }
        });
    }, [loading]);

    useEffect(() => {
        // 該教材修改歷程 API
        DuckIO.doGet(AppConfig.REPO_COMMITS(id), null, (result) => {
            if (!Array.isArray(result.response)) {
                alert('請洽管理員!')
            } else {
                let data = result.response.filter(x=>{
                    return x.hidden === false
                })
                setHisData(data);
                setHisLoading(false);
            }
        });

    }, [hisLoading]);

    useEffect(() => {
        // 留言回饋API
        DuckIO.doGet(AppConfig.REPO_MSG_FEEDBACK(id), null, (result) => {
            if (!Array.isArray(result.response)) {
                alert('REPO_MSG_FEEDBACK 請洽管理員!')
            } else {
                setMsgFbData(result.response.sort((x,y)=> new Date(y.createAt) - new Date(x.createAt)));
                setMsgFbLoading(false);
            };
        });
    }, [msgFbLoading]);

    useEffect(() => {
        // 檔案回饋API
        DuckIO.doGet(AppConfig.REPO_FILE_FEEDBACK(id), null, (result) => {
            if (!Array.isArray(result.response)) {
                alert('REPO_FILE_FEEDBACK 請洽管理員!')
            } else {
                setFileFbData(result.response.sort((x,y)=> new Date(y.createAt) - new Date(x.createAt)));
                setFileFbLoading(false);
            };
        });
    }, [fileFbLoading]);

    // 對回饋再回覆
    const replySubmit = (id,v,replyFromNum,type) => {
        if(type === 'msg') {
            setReplySubmitLoading(true);
            let data = {
                repoId : id,
                message: v,
                replyFromNum: replyFromNum
            };
            DuckIO.doPost(AppConfig.POST_REPLY_FEEDBACK(), data, null, (result) => {
                setMsgFbLoading(!msgFbLoading); //重新撈取留言回饋
                setReplySubmitLoading(false);
                setActiveReply(null); //隱藏留言框
                setMsgFeedback('');
            });
            
        } else if (type === 'file') {
            setReplySubmitLoading(true);
            let data = {
                repoId : id,
                message: v,
            };
            DuckIO.doPost(AppConfig.POST_REPLY_FEEDBACK(), data, null, (result) => {
                setFileFbLoading(!fileFbLoading); //重新撈取教材回饋
                setReplySubmitLoading(false);
                setActiveReply(null); //隱藏留言框
                
            });
        } else {
            alert('type not found 請洽管理員!');
            return;
        }
    };

    if (!repoFound) {
        return (
            <div>
                <NotFound title={'沒有此項教材喔'} />
                <div style={{ textAlign: 'center' }}>
                    <NavLink to="/">
                        <Button block type="primary">
                            回首頁
                        </Button>
                    </NavLink>
                </div>
            </div>
        );
    } 

    const meta = {
        meta: {
            charset: 'utf-8',
            name: {
                "og:title": data.title,
                "og:description": data.intro || '無簡介'
            }
        }
    }

    return (
        <div className="detail">
            {loading ? <Loading /> : 
                <>
                    <DocumentMeta { ...meta}>
                    </DocumentMeta>
                    <Row gutter={[16, 16]} // 設定間距
      style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Col xs={24} sm={12} style={{ textAlign: 'left' }}>
                            <Title level={3}>{data.title}
                            {data.invalid ? <Tag style={{marginLeft: '3px'}}  bordered={false} color='red' key={data.classId}>已下架</Tag>:  ''}
                            </Title>
                        </Col>
                        
                        <Col xs={24} sm={12}>
                            <div className="like-star">
                                <div style={{ fontSize: 20 }} title='按讚次數'>
                                    <LikeFilled  style={{ fontSize: 25, color: '#2CA6D4' }} />
                                    {data.like_count || 0}
                                </div>
                                <div style={{ marginLeft: 20, fontSize: 20 }} title='收藏次數'>
                                    <StarFilled style={{ fontSize: 25, color: '#F6A700' }} />
                                    {data.archive_count || 0}
                                </div>
                                <div style={{ marginLeft: 20, fontSize: 20 }} title='下載次數'>
                                    <DownloadOutlined style={{ fontSize: 25, color: '#D44C26' }} />
                                    {data.download_count || 0}
                                </div>
                            </div>   
                        </Col>
                    </Row>
                    <div className="detail_item">教學領域：
                    {(data.class || []).map((y,i)=>{
                        return <Tag color='green' key={i}>{y.className}</Tag>
                    })}
                    </div>
                    <div className="detail_item">教材年級：
                        <Tag color='blue'>{!!data.grade ? data.grade.label : ""}</Tag>
                    </div>
                    <div className="detail_item">障礙類別：
                        {
                        data.spobs.length === 0 ? '無' :
                        (data.spobs || []).map((y,i)=>{
                            return <Tag color='green' key={i}>{y.spOpsName}</Tag>
                        })}
                    </div>
                    <div className="detail_item">教材發起人：{data.teacher}</div>
                    <div className="detail_item">
                        共同協作人：
                        {(data.collaboratives || []).join('、')}
                    </div>
                    <div className="detail_item">教材簡介：</div>
                    <div>{data.intro || '無簡介'}</div>
                </>
            }
            
            
            <div className="detail_item">
            <Title level={3}>教材更新紀錄</Title>
            </div>
            {hisLoading ? <Loading /> : 
            <>
                <List
                    bordered
                    dataSource={hisData}
                    renderItem={(x) => (
                        <List.Item>
                            <Row gutter={[16, 16]} // 設定間距
      style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Col xs={24} sm={12} style={{ textAlign: 'left' }}>
                                    <div>
                                        <Tag color="orange">教材版本{x.commitVer}</Tag>
                                        <div className='record-date'>{x.date}</div>
                                        <List.Item.Meta
                                            description={x.message}
                                            
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} sm={12} className="align_rtc" >
                                    {!!AppConfig.MEMBER ? 
                                    <div className="button-container">                                    
                                        <Button type="default"
                                        icon={<DownloadOutlined />}
                                        loading={btnId===x.id} 
                                        disabled={btnId !== x.id && downloadLoading} 
                                        onClick={() => {
                                            setBtnId(x.id);
                                            setDownloadLoading(true)
                                            DuckIO.download(DuckIO.path(AppConfig.REPO_DOWNLOAD_ARCHIVE(id, x.id)), `${id}.zip`, () => {
                                                message.success('下載成功');
                                                setBtnId('');
                                                setDownloadLoading(false)
                                            });
                                        }}> 
                                        {btnId===x.id ? '下載中' :'下載檔案'}
                                        </Button>
                                        <Button type="primary" shape="round" icon={<MessageOutlined />} onClick={(event) => {
                                                setFeedBackModalType(['msg',x.commitVer]);
                                            
                                            }}>對此版本留言
                                        </Button>
                                        <Button  type="primary"shape="round" icon={<FileAddOutlined />} onClick={(event) => {
                                                setFeedBackModalType(['file',x.commitVer]);
                                            }}>對此版本分享教材
                                        </Button>
                                    </div> : <div className="button-container"> <Button disabled={true}>登入後下載</Button> </div>
                                    }
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </>
            }
            
            <div className="detail_item">
                <Title level={3}>留言與教材分享</Title>
                { (msgFbLoading || fileFbLoading) ? <Loading /> : 
                <Tabs
                    defaultActiveKey="1"
                    items={[MessageOutlined, FileTextOutlined ].map((Icon, i) => {
                    const id = String(i + 1);
                    return {
                        label: (
                        <span>
                            <Icon key={i}/>
                            {id === '1' ? '留言板' : '教材回饋'}
                        </span>
                        ),
                        key: id,
                        children:  id === '1' ? 
                        <List
                            key={i}
                            locale={{emptyText: '目前無留言'}}
                            bordered
                            dataSource={msgFbData} //留言回饋
                            renderItem={(x) => ( 
                            <Row align='middle' style={{padding: '0px 12px 12px', borderBottom: '1px lightgray solid'}} key={x.replyFromNum}>
                                <Col span={24}>
                                    <R_Comment data={x} 
                                        actions={[
                                            <Button disabled={!AppConfig.MEMBER} size='small' type='default' onClick={()=>{
                                                setActiveReply(
                                                    (activeReply === null || activeReply!== x.replyFromNum) ? x.replyFromNum : null
                                                    );}}>
                                                {(activeReply !== x.replyFromNum) ? '回覆' : '取消'}
                                            </Button>
                                        ]}
                                    />
                                    {activeReply === x.replyFromNum && 
                                    <Space.Compact block size="small">
                                        <Input
                                            style={{width: '100%'}}
                                            disabled={replySubmitLoading}
                                            placeholder="請輸入留言" 
                                            onChange={(e)=>{
                                            setMsgFeedback(e.target.value);
                                            }}
                                        />
                                        <Button type="primary" onClick={()=>{
                                            replySubmit(x.id,msgFeedback,x.replyFromNum,'msg');

                                        }}>送出</Button>
                                    </Space.Compact>
                                    }
                                </Col>
                                {/* <Col xs={{span: 8,offset:4}} sm={{span: 4,offset:8}} lg={{span:2, offset:10}}>
                                    <Tag color='orange'>{`教材版本${x.commitVer}`}</Tag>
                                </Col> */}
                            </Row> 
                            )}
                        />
                        :  
                        <List
                            bordered
                            locale={{emptyText: '目前無回饋'}}
                            dataSource={fileFbData} // 檔案回饋
                            renderItem={(x) => ( 
                            <Row align='middle' style={{padding: '0px 12px 12px', borderBottom: '1px lightgray solid'}} key={`file-${x.id}`}>
                                <Col span={12}>
                                    <R_Comment data={x} 
                                        actions={[
                                            <Button disabled={!AppConfig.MEMBER} size='small' type='default' onClick={()=>{
                                                setActiveReply((activeReply === null || activeReply!== `file-${x.id}`) ? `file-${x.id}` : null);}}>
                                            {(activeReply !== `file-${x.id}`) ? '回覆' : '取消'}</Button>
                                        ]}
                                    />
                                    {activeReply === `file-${x.id}` && 
                                        <Space.Compact block size="small">
                                            <Input
                                                style={{width: '100%'}}
                                                disabled={replySubmitLoading}
                                                placeholder="請輸入留言" 
                                                onChange={(e)=>{
                                                    setMsgFeedback(e.target.value)
                                                }}
                                            />
                                            <Button type="primary" loading={replySubmitLoading} onClick={()=>{
                                                {replySubmit(x.id,msgFeedback,`file-${x.id}`,'file')}
                                            }}>送出</Button>
                                        </Space.Compact>
                                    }
                                </Col>
                                <Col xs={24} sm={12} className="align_rtc">
                                
                                {!!AppConfig.MEMBER ?
                                <div className="button-container">
                                <Button type="default"
                                    style={{ backgroundColor: btnId !== x.id && downloadLoading ? '#F5F5F5' :'#608BA4',
                                             color:btnId !== x.id && downloadLoading ? 'rgba(0, 0, 0, 0.25)' :'white' ,
                                             marginTop: '5px'
                                            }}   
                                    icon={<DownloadOutlined />}
                                    loading={btnId===x.id} 
                                    disabled={btnId !== x.id && downloadLoading} 
                                    onClick={() => {
                                        setBtnId(x.id);
                                        setDownloadLoading(true)
                                        DuckIO.download(DuckIO.path(AppConfig.REPO_DOWNLOAD_ARCHIVE(x.id)), `${x.id}.zip`, () => {
                                            message.success('下載成功');
                                            setBtnId('');
                                            setDownloadLoading(false);
                                        });
                                    }}
                                    >
                                    {btnId===x.id ? '下載中' :'下載檔案'}
                                </Button>
                                </div> : <div className="button-container"> <Button disabled={true}>登入後下載</Button> </div>}
                                </Col> 
                            </Row> 
                            )}
                        />,
                    };
                    })}
                />
               
                }
            </div>
            {feedBackModalType && 
                <FeedbackModal 
                    feedBackModalType={feedBackModalType} 
                    setFeedBackModalType={setFeedBackModalType} 
                    repoData={data}
                    setMsgFbLoading={setMsgFbLoading}
                    msgFbLoading={msgFbLoading}
                    fileFbLoading={fileFbLoading}
                    setFileFbLoading={setFileFbLoading}
                />
            }
            
        </div> 
    );
};
